
import accountx from '@/store/modules/accountx';
import moment from 'moment';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class MyPlan extends Vue {
	public get fullAccountData() {
		return accountx.fullAccountData;
	}

	public get expireDate() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return moment(this.fullAccountData.expiryTime).format('DD MMM YYYY');
	}

	public get expired() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return this.fullAccountData.token.forbidden;
	}

	public get planType() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return this.fullAccountData.programId === 'sql-dashboard'
			? 'Starter'
			: 'Premium';
	}

	public get daysRemaining() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return moment(this.fullAccountData.expiryTime).diff(moment(), 'd') > 0
			? moment(this.fullAccountData.expiryTime).diff(moment(), 'd')
			: 0;
	}
}
